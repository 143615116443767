@charset "UTF-8";
body {
    font-family: calibri, sans-serif;
    font-style: normal;
    font-weight: 300;
}

.data-wrap {
    * {
        box-sizing: border-box;
    }
}

.data-filter {
    margin: 0 -10px 30px -10px;
    &:after {
        content: "";
        display: block;
        clear: both;
    }
    .filter-box {
        width: 25%;
        float: left;
        padding: 0 10px;
        @media (max-width: 768px) {
            width: 50%;
            margin-bottom: 10px;
        }
        @media (max-width: 414px) {
            width: 100%;
        }
        .drop-box {
            cursor: pointer;
            position: relative;
            background: #fecb03;
            border-radius: 20px;
            padding: 10px 20px;
            margin-bottom: 5px;
            &.unable {
                background: #f2f2f2;
                cursor: default;
                ul {
                    &.ul-show {
                        opacity: 0;
                        visibility: hidden;
                    }
                }
            }
        }
        .selected-box {
            .remove-selected {
                background: #030098;
                color: #fecb03;
                margin-bottom: 5px;
                padding: 10px 20px 10px 40px;
                border-radius: 20px;
                font-size: 14px;
                position: relative;
                button {
                    outline: none;
                    background: none;
                    border: none;
                    color: #fecb03;
                    padding: 0;
                    cursor: pointer;
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    left: 15px;
                    top: 9px;
                    &:before {
                        content: "";
                        display: block;
                        width: 2px;
                        height: 12px;
                        background: #fecb03;
                        transform: rotate(45deg);
                        position: absolute;
                        left: 10px;
                        top: 4px;
                    }
                    &:after {
                        content: "";
                        display: block;
                        width: 2px;
                        height: 12px;
                        background: #fecb03;
                        transform: rotate(-45deg);
                        position: absolute;
                        left: 10px;
                        top: 4px;
                    }
                }
            }
        }
        .select-title {
            font-size: 16px;
            color: #030098;
            font-weight: bold;
            padding-right: 20px;
            position: relative;
            &:after {
                content: "";
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 8px 7px 0 7px;
                border-color: #030098 transparent transparent transparent;
                position: absolute;
                right: 0;
                top: 7px;
            }
        }
        ul {
            transition: all 0.2s ease-in-out;
            opacity: 0;
            visibility: hidden;
            max-height: 300px;
            overflow: auto;
            position: absolute;
            z-index: 9;
            width: 100%;
            margin: 0;
            padding: 10px;
            background: #fff;
            bottom: 0;
            left: 0;
            transform: translate3d(0, 100%, 0);
            border-radius: 20px;
            box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.13);
            &.ul-show {
                opacity: 1;
                visibility: visible;
            }
            li {
                list-style: none;
                &.selected {
                    display: none;
                }
                &.mix-unselect {
                    display: none;
                }
                &.c-unselect {
                    display: none;
                }
                &.r-unselect {
                    display: none;
                }
                &.s-unselect {
                    display: none;
                }
                &.t-unselect {
                    display: none;
                }
                button {
                    outline: none;
                    display: block;
                    background: none;
                    border: none;
                    cursor: pointer;
                    width: 100%;
                    text-align: left;
                    padding: 7px 12px;
                    color: #030098;
                    font-size: 16px;
                    border-radius: 20px;
                    &:hover {
                        background: #030098;
                        color: #fecb03;
                    }
                }
            }
        }
    }
}

.data-table {
    .round-row {
        margin-bottom: 20px;
        &.filter-hide {
            display: none;
        }
        &.no-child {
            display: none;
        }
        .round-title {
            //   background: #030098;
            border: 1px #030098 solid;
            color: #030098;
            margin-bottom: 10px;
            border-radius: 30px;
            padding: 10px 20px;
            font-weight: bold;
            font-size: 20px;
            .mobile {
                display: none;
            }
            .widescreen {
                display: block;
            }
            @media (max-width: 768px) {
                .mobile {
                    display: block;
                }
                .widescreen {
                    display: none;
                }
            }
        }
        .sport-row {
            margin-bottom: 20px;
            &.filter-hide {
                display: none;
            }
            &.no-child {
                display: none;
            }
            .sport-title {
                background: #fecb03;
                color: #030098;
                margin-bottom: 5px;
                border-radius: 30px;
                padding: 7px 20px;
                font-weight: bold;
                font-size: 17px;
            }
            .table-wrap {
                padding: 0 50px;
                @media (max-width: 868px) {
                    padding: 0;
                }
            }
            table {
                //              min-width: 768px;
                width: 100%;
                @media (max-width: 768px) {
                    display: block;
                }
                thead {
                    @media (max-width: 768px) {
                        display: none;
                    }
                    tr {
                        background: #030098;
                        th {
                            text-align: left;
                            // width: 16.666%;
                            width: 12%;
                            color: #fecb03;
                            padding: 8px;
                            font-size: 14px;
                        }
                    }
                }
                tbody {
                    @media (max-width: 768px) {
                        display: block;
                    }
                    tr {
                        background: #fff;
                        @media (max-width: 768px) {
                            display: block;
                            margin-bottom: 20px;
                        }
                        &:nth-child(2n) {
                            background: #f2f2f2;
                            td {
                                @media (max-width: 768px) {
                                    border-bottom: 1px solid #fff;
                                }
                            }
                        }
                        &.bg-row {
                            background: #f2f2f2;
                            td {
                                @media (max-width: 768px) {
                                    border-bottom: 1px solid #fff;
                                }
                            }
                        }
                        &.white-row {
                            background: #fff;
                        }
                        &.team-filter-hide {
                            display: none;
                        }
                        &.campus-filter-hide {
                            display: none;
                        }
                        &.school-hide {
                            display: none;
                        }
                        td {
                            padding: 8px;
                            font-size: 14px;
                            color: #000;
                            @media (max-width: 768px) {
                                padding-left: 50%;
                                min-height: 36px;
                                display: block;
                                border: none;
                                border-bottom: 1px solid #eee;
                                position: relative;
                                &:before {
                                    width: 45%;
                                    padding-right: 10px;
                                    white-space: nowrap;
                                    display: block;
                                    font-weight: bold;
                                    position: absolute;
                                    left: 10px;
                                    top: 0px;
                                    min-height: 36px;
                                    line-height: 36px;
                                }
                                &:nth-child(1) {
                                    &:before {
                                        content: "Date: ";
                                    }
                                }
                                &:nth-child(2) {
                                    &:before {
                                        content: "Start Time: ";
                                    }
                                }
                                &:nth-child(3) {
                                    &:before {
                                        content: "Team: ";
                                    }
                                }
                                &:nth-child(4) {
                                    &:before {
                                        content: "Opponent: ";
                                    }
                                }
                                &:nth-child(5) {
                                    &:before {
                                        content: "Location: ";
                                    }
                                }
                                &:nth-child(6) {
                                    &:before {
                                        content: "Address: ";
                                    }
                                }
                                &:nth-child(7) {
                                    &:before {
                                        content: "Venue: ";
                                    }
                                }
                                &:nth-child(8) {
                                    &:before {
                                        content: "Draw Status: ";
                                    }
                                }
                            }
                            a {
                                text-decoration: none;
                                color: #030098;
                                &:hover {
                                    color: #000;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.no-fixtures {
    display: grid;
    place-items: center;
    font-size: 20px !important;
    span {
        text-transform: capitalize;
    }
}